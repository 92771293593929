import React from 'react'
import "./Olympiad.css"
const Olympiad = () => {
    return (
        <>
            <h1 className="section-title">
                Olympiad   <span>Exams</span>
            </h1>
            <div className="marquee">
                    
                    <span><h3 className="sub-head"> Registrations to Olympiad Exams will start from 15-Nov-2024 </h3></span>
            </div>
            <div className='main-olympiad'>
                <h2 className='main-heading'>
                    EPIC’s National Level Olympiad Exams
                </h2>
                <p className='sub-para1'>
                    <b>"Olympiads"</b> typically refer to academic competitions or contests conducted at national or international levels to test and recognize excellence in various subjects among students. National Level Olympiad Exams play a crucial role in nurturing talent, fostering academic excellence, and preparing students to face future challenges in their chosen fields. They serve as a valuable avenue for personal and academic growth.
                </p>
                <h3 className='main-head2'>EPIC conducts National Level Olympiad Exams Twice in a year (July & January) </h3>
                <p className='sub-para2'>to identify and nurture young talent in specific fields such as Science, Computer Science, Information Technology and more. These exams aim to <b>Promote Academic Excellence, </b> Benchmarking Skills, Foster Critical Thinking, Recognition and Awards.</p>
                <h3 className='main-head3'>Currently we conduct Olympiads only for our COP (Computer Outreach Program) for all grade students. </h3>
                <p className='sub-para3'>These Olympiads are not limited to EPIC registered students while any one falling in different grades, across India, can participate in EPIC’s Olympiads exams-</p>
                <h3 className='para-sub-heading1'>
                    ⦁	JDO (Junior Digital Wizards Olympiad) - Primary (Grade 1st – 5th)

                </h3>
                <h3 className='para-sub-heading2'>⦁	CCO (Cyber Champions Olympiad) –    Middle Level (Grade 6th -8th)</h3>
                <h3 className='para-sub-heading3'>⦁	NCO (National Cyber Coding Olympiad) – Secondary (Grade 9th – 10th)</h3>
                <h3 className='para-sub-heading4'>
                    ⦁	NSP (National Science Olympiad) – Higher Secondary (Grade 11th – 12th)
                </h3>
                <h2 className='last-head'>Important details about the EPIC’s COP Olympiads.</h2>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Exam Organizing Body</th>
                        <th>EPIC (Technical Education Outreach Program Council of India)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Eligibility</td>
                        <td>Students from classes 1 - 12, enrolled in EPIC COP program or school authorities registered in EPIC are eligible for COP Olympiads.</td>
                    </tr>
                    <tr>
                        <td>Exam Level</td>
                        <td>Intermediate</td>
                    </tr>
                    <tr>
                        <td>Application Process</td>
                        <td>Online</td>
                    </tr>
                    <tr>
                        <td>Exam Dates</td>
                        <td>Level 1 Exam Date July 15-30. <br /> Level 1 Exam Date January 15-30 of Every Year.</td>
                    </tr>
                    <tr>
                        <td>Exam Mode</td>
                        <td>Online</td>
                    </tr>
                    <tr>
                        <td>Fee of registration</td>
                        <td>₹ 250</td>
                    </tr>
                    <tr>
                        <td>Frequency of Conduct</td>
                        <td>Twice in a year</td>
                    </tr>
                    <tr>
                        <td>Objective</td>
                        <td>To identify & foster passion for Information Technology & Programming Skills.</td>
                    </tr>
                    <tr>
                        <td>Languages</td>
                        <td>English</td>
                    </tr>
                    <tr>
                        <td>Duration</td>
                        <td>1 hour</td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default Olympiad
