import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Pages/Home'
import About from "./Pages/About/About";
import Ourprogram from "./Pages/ourProgram/Ourprogram"
import Contact from './Pages/Contact/Contact';
import Faq from './Pages/Faq/Faq';
import ScrollToTop from './ScrollToTopOnPageChange';
import Register from './Pages/Register/Register';
import WaitBox from './components/temp/WaitBox';
import { useState } from 'react';
import Style from "./App.module.css"


function App() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Router>
        <div className={Style.relativeContainer}>
          {isOpen && <WaitBox setIsOpen={setIsOpen}/>}
        </div>
        <ScrollToTop />
        <Navbar setIsOpen={setIsOpen} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/ourprogram" element={<Ourprogram />} />
          <Route path="/register" element={<Register />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<Faq />} />
        </Routes>
        <Footer />

      </Router>
    </>
  );
}

export default App;

