import React from 'react'
import './Faq.css';
const Faq = () => {
  return (
    <div className='main-cotainair'>
     <ol >
    <li className='question'>Q. What is EPIC?</li>
    <ul className='answer'>Answer: EPIC (Technical Education Outreach Program Council of India) is an organization dedicated to promoting and enhancing technical education across India.Our mission is to empower students with the skills and knowledge required to excel in the ever-evolving tech industry</ul>
    <li className='question'>Q. Who can participate in EPIC programs?</li>
    <ul className='answer'>Answer: EPIC programs are open to students of various age groups and educational levels, including primary, middle, and high school students, as well as college students and professionals seeking to enhance their technical skills.</ul>
    <li className='question'>Q. What types of programs does EPIC offer?</li>
   <ul className='answer'>Answer: EPIC offers a wide range of programs, including coding workshops, robotics courses, artificial intelligence training, internships, mentorship opportunities, and competitions like the National Junior Coders Olympiad.</ul>
    <li className='question'>Q. How can I apply for EPIC scholarships and grants? </li>
    <ul className='answer'>Answer: To apply for EPIC scholarships and grants, visit our website and navigate to the Scholarships and Grants section. You will find detailed information on eligibility criteria, application deadlines, and the required documentation.</ul>
    <li className='question'>Q. What are the benefits of industry partnerships with EPIC?</li>
    <ul className='answer'>Answer: Industry partnerships with EPIC provide students with hands-on experience,Answer: Industry partnerships with EPIC provide students with hands-on experience, internships, and mentorship opportunities from leading tech companies. These collaborations ensure our curriculum is aligned with industry standards, preparing students for successful careers in technology.
    internships, and mentorship opportunities from leading tech companies. These collaborations ensure our curriculum is aligned with industry standards, preparing students for successful careers in technology.</ul>
    <li className='question'>Q.How does EPIC support teachers and educators? </li>
    <ul className='answer'>Answer: EPIC offers comprehensive training programs for teachers and educators, equipping them with the latest teaching methodologies and technological tools. This ensures that they can effectively teach and inspire students in the field of technology.</ul>
    <li className='question'>Q. How can schools and educational institutions collaborate with EPIC?</li>
    <ul className='answer'>Answer: Schools and educational institutions can collaborate with EPIC by partnering with us to implement our programs, host workshops and competitions, and provide their students with access to our resources and expertise.</ul>
    <li className='question'>Q. Are there any competitions organized by EPIC?</li>
    <ul className='answer'>Answer: Yes, EPIC organizes various competitions, such as the National Junior Coders Olympiad, to encourage innovation and creativity among students. These competitions provide a platform for students to showcase their skills and gain recognition.</ul>
    <li className='question'>Q. How can I get involved with EPIC as a volunteer or mentor?</li>
    <ul className='answer'>Answer: To get involved with EPIC as a volunteer or mentor, visit our website and navigate Answer: To get involved with EPIC as a volunteer or mentor, visit our website and navigate to the Get  Involved section. You can find information on current volunteer opportunities and the application process for becoming a mentor.
to the Get Involved section. You can find information on current volunteer opportunities and the application process for becoming a mentor.</ul>
    <li className='question'>Q. Where can I find more information about EPIC programs and initiatives?</li>
    <li className='answer'>Answer: For more information about EPIC programs and initiatives, visit our official website. You can also follow us on our social media channels for the latest updates and announcements.</li>
    </ol>
    <br />
    </div>
    
  )
}

export default Faq