import React from "react";
import "./Syllabus.css";

function Syllabus({ backgroundImage, property, property2, props = {} }) {
    const { main, li1, li2, li3, li4, li5, li6, li7, li8, li9, li10, li11, li12, li13, li14, li15, buttons } = props;

    const handleButtonClick = (href) => {
        window.location.href = href;
    };

    return (
        <div
            className="contacts-page-container"
            style={{
                backgroundImage: `url(${backgroundImage})`,
                justifyContent: property,
                marginLeft: property2 // Corrected Marginleft to marginLeft
            }}
        >
            <div className="img"></div>
            <div className="contact-Form">
                <h2>{main}</h2>
                <ul>
                    <li>{li1}</li>
                    <li>{li2}</li>
                    <li>{li3}</li>
                    <li>{li4}</li>
                    <li>{li5}</li>
                    <li>{li6}</li>
                    <li>{li7}</li>
                    <li>{li8}</li>
                    <li>{li9}</li>
                    <li>{li10}</li>
                    <li>{li11}</li>
                    <li>{li12}</li>
                    <li>{li13}</li>
                    <li>{li14}</li>
                    <li>{li15}</li>
                </ul>
                <div >
                    {buttons && buttons.map((button, index) => (
                        <button className="cta" key={index} onClick={() => handleButtonClick(button.href)}>
                            {button.text}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Syllabus;
