import React from "react";
import "./Footer.css";
import { NavLink } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai";
import {
  FaFacebookSquare,
  FaLinkedin,
  FaYoutubeSquare,
} from "react-icons/fa";
import { scroller } from "react-scroll";

const Footer = () => {

  const subscribeNewsLetter = () => {

  }
  return (
    <>
      {/* ==================== FOOTER ==================== */}
      <footer className="footer">
        <div className="footer__container container grid">
          <div className="footer__content grid">
            <div className="footer__data">
              <h3 className="footer__subtitle">About Us</h3>
              <ul>
                <li className="footer__item">
                  <NavLink to={{ pathname: "/about", state: "about-us", hash: "#about-top" }} className="footer__link"
                  > About Us </NavLink>

                </li>
                <li className="footer__item">
                  <NavLink to={{ pathname: "/about", state: "vision", hash: "#vision" }} className="footer__link"  > Vision </NavLink>
                </li>
                <li className="footer__item">
                  <NavLink to={{ pathname: "/about", state: "mission", hash: "#mission" }} className="footer__link"  > Mission </NavLink>
                </li>
              </ul>
            </div>

            <div className="footer__data">
              <h3 className="footer__subtitle">Our Programs</h3>
              <ul>
                <li className="footer__item">
                  <NavLink to={{ pathname: "/ourprogram", state: "vision", hash: "#cop" }} className="footer__link"  > Cop </NavLink>

                </li>

                <li className="footer__item">
                  <NavLink to={{ pathname: "/ourprogram", state: "vision", hash: "#stop" }} className="footer__link"  > Stop </NavLink>

                </li>
                <li className="footer__item">
                  <NavLink to={{ pathname: "/ourprogram", state: "vision", hash: "#Olympiads" }} className="footer__link"  > Olympiads </NavLink>
                </li>
              </ul>
            </div>
            <div className="footer__data">
              <h3 className="footer__subtitle">Registration</h3>
              <ul>
                <li className="footer__item">
                  <NavLink
                    to="https://docs.google.com/forms/d/e/1FAIpQLSd1DzVa4a1W17xGaWmddwxFK3hVSjeNc3Q4Le4S2PQkn9Diqw/viewform?usp=sf_link"
                    className="footer__link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Online Registration
                  </NavLink>

                </li>
              </ul>
            </div>
            {/* <div className="footer__data">
              <h3 className="footer__subtitle">Get Involved</h3> */}
            {/* <ul>
                <li className="footer__item">
                  <a href="" className="footer__link">
                  Individuals	
                  </a>
                </li>
                <li className="footer__item">
                  <a href="" className="footer__link">
                  Partnerships	
                  </a>
                </li>
                <li className="footer__item">
                  <a href="" className="footer__link">
                  Internships 
                  </a>
                </li>
                <li className="footer__item">
                  <a href="" className="footer__link">
                  Work with Us
                  </a>
                </li>
              </ul> */}
            {/* </div> */}
            <div className="footer__data">
              <h3 className="footer__subtitle">Contact Us</h3>
              <ul>
                <li className="footer__item">
                  <NavLink to={{ pathname: "/contact" }} className="footer__link"  > Contact Us </NavLink>

                </li>
              </ul>
            </div>
            <div className="footer__data">
              <h3 className="footer__subtitle">FAQ's</h3>
              <ul>
                <li className="footer__item">
                  <NavLink to={{ pathname: "/faq" }} className="footer__link"  > FAQ's </NavLink>
                </li>
              </ul>
            </div>
            <div className="footer__data">
              {/* <h3 className="footer__subtitle">Subscribe Newsletter</h3>
              <form class="footer-email flex" onSubmit={subscribeNewsLetter}>
                <input type="text" placeholder="Email" />
                <button class="footer-subscribe-btn flex" type="submit">
                  <AiOutlineMail className="subscribe-img" />
                  <p>Subscribe</p>
                </button>
              </form> */}

              <div class="footer-socials">

                <a href="" target="">
                  <FaFacebookSquare className="facebook" />
                </a>

                <a href="" target="">
                  <FaLinkedin className="linkedin" />
                </a>

                <a href="" target="">
                  <FaYoutubeSquare className="youtube" />
                </a>
              </div>
            </div>
          </div>

          <div className="footer__rights">
            <p className="footer__copy">
              &#169;2024 Technical Education Outreach Program Council of India. All rigths reserved.
            </p>
            {/* <div className="footer__terms">
              <a href="https://icoesolution.com/" className="footer__terms-link">
              Developed & Powered by ICOESS Solutions Pvt. Ltd.
              </a>
            </div> */}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
