import React from 'react'
import Typography from "@mui/material/Typography";

const TillClass12 = () => {
    return (
        <div style={{ marginBottom: '1rem' }}>
            <div style={{ width: '100%', height: 'auto', borderRadius: '5px' }}>
                <Typography variant="h5" gutterBottom style={{ fontSize: '2rem', color: 'teal' }}>
                    Higher Secondary (11th -12th) Future Innovators - Creative Coding
                </Typography>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Introduction to Information Technology and its scope in a broader sense</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Definition and evolution of Information Technology (IT)</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Importance and scope of IT in various fields (education, business, Healthcare, entertainment, etc.)</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Role of IT in data management and processing</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Basics of networking and internet technologies</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; E-commerce, e-governance, and other IT-enabled services</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Introduction to Different Types of Computer Technologies Available Today</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Web Technology</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Cloud Computing</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Data Science</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Cyber Security</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Artificial Intelligence (AI) and Machine Learning (ML) Systems</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Internet of Things (IoT)</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Virtual Reality (VR) and Augmented Reality (AR)</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Robotics</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>MS Office (Word, Excel & PowerPoint) with Practical & Projects</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; MS Word</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Getting Started with Microsoft Word</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Text Manipulation</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Formatting Documents</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Applying and Modifying Styles, Using Themes and Templates</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Working with Headers and Footers, Inserting Page Numbers, Dates, and Times</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Adjusting Page Layout: Margins, Orientation, and Size</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Formatting Objects & Advanced Formatting</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Document Review and Collaboration, Mail Merge, Forms, and Templates</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Macros and Automation, Integrating Word with Other Microsoft Office Applications (Excel, PowerPoint, and Outlook)</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Customizing Word Environment for Efficiency</li>
                </ul>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; MS Excel</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Understanding Spreadsheet Software</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Overview of Microsoft Excel and its Interface</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Workbook and Worksheet Basics</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Creating, Opening, and Saving Workbooks</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Cell Formatting (Formatting Numbers, Dates, Text, Applying Borders, and Conditional Formatting)</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Basic Formulas and Functions, Data Visualization, Data Sorting, and Filtering</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Data Analysis Tools</li>
                </ul>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; MS PowerPoint</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Introduction to Presentations and the Need for Presentations</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Overview of PowerPoint and Creating Different Types of Presentations</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Introduction to Programming Languages & Different Types of Computer Languages</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Understanding What a Computer Programming Language Is</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Importance of Programming in the Modern World</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Generations & Types of Programming Languages (First to Fifth Generations)</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Different Programming Languages Available (Low-Level, Assembly, High-Level)</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Programming Language Concepts (Syntax, Semantics, Variables, Data Types, Control Structures)</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Programming using ‘Python’ (Implementing All Programming Concepts)</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Introduction to Python: History, Features, and Installation</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Python IDEs: IDLE, PyCharm, VS Code</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Basics of Python Programming</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Control Structures: Conditional Statements, Looping Constructs, Nesting, Break, Continue, Pass</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Functions and Modules: Defining, Calling, Arguments, Return Values, Scope, Lifetime, Standard Library</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Strings and File Handling: String Manipulation, Methods, File Operations, Exception Handling</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Exception Handling: Understanding Exceptions, Using Try-Except Blocks</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Working with Databases: SQLite, SQL Databases, Python Connectivity</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Advanced Topics and Projects: Introduction to Machine Learning Basics</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>OOPS Concepts</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Understanding Classes and Objects, Defining Classes, Creating Objects</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Working with Attributes, Methods, Inheritance, Polymorphism, Encapsulation</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Basic Java Programming</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; History and Features of Java</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Java Development Kit (JDK) and Java Runtime Environment (JRE)</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Setting up the Environment: Installing JDK and Configuring IDE (Eclipse)</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Basics of Java Programming: Data Types, Variables, Operators, Expressions</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Control Structures: Conditional Statements, Looping Constructs, Nested Loops</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Arrays, Strings, Methods, Functions, OOP with Java</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Exception Handling</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>DBMS & RDBMS</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Understanding Data and Information</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; File-Based Systems vs. Database Systems</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Overview of Database Management System (DBMS)</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Database Concepts and Architecture, Data Models</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Relational Model and SQL</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Introduction to Web Technology & Client/Server Architecture</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Understanding the Internet and the World Wide Web (WWW)</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Difference Between the Internet and the WWW</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Basic Concepts of Web Browsers and Web Servers</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Overview of Web Technologies and Their Applications</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Web Designing & Wireframing</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Understanding the Importance of Web Design</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Difference Between Web Design and Web Development</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Use of Wireframing in Web Design</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Tools for Wireframing (Figma, Adobe XD)</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>HTML Programming & CSS</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Introduction to HTML, HTML Elements, and Attributes</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; HTML Forms, Input, and Tables</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Introduction to CSS: Role in Web Design, Syntax, Structure</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Inline, Internal, and External CSS, Applying Styles to HTML Elements</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; CSS Selectors and Properties, Box Model, and Layout</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Responsive Design with CSS</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>AI & Its Areas of Use</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; What is Artificial Intelligence?</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; History and Evolution of AI</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Different Types of AI</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Fundamentals of Machine Learning</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; AI in Real Life</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Generative AI & AI Tools</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Overview of Generative Models: Types and Applications</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Differences Between Generative AI and Traditional AI Models</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Overview of Popular Generative AI Tools and Platforms (ChatGPT, GitHub)</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Introduction to Cloud Computing</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Introduction to Cloud Computing</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Different Cloud Computing Technologies Available Today</li>
                </ul>
            </div>
        </div>
    )
}

export default TillClass12
