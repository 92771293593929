import React, { useState } from "react";


import "./HoverComponentView.css";


import HoverCard from "../cards/HoverCard";

const HoverComponentView = () => {
  const [isDefault, setIsDefault] = useState(true);
  const [isHealthCare, setIsHealthCare] = useState(false);
  const [isEcomm, setIsEcomm] = useState(false);
  const [isEducation, setIsEducation] = useState(false);
  const [isRealEstate, setIsRealEstate] = useState(false);
  const [isMedia, setIsMedia] = useState(false);
  const [isLogistics, setIsLogistics] = useState(false);
  const [isTourism, setIsTourism] = useState(false);

  // Health Care Function Starts
  function healthCareEnter() {
    setIsHealthCare(true);
    setIsDefault(false);
  }
  function healthCareLeave() {
    setIsHealthCare(false);
    setIsDefault(true);
  }
  // Health Care Function Ends

  // Ecomm Function Starts
  function EcommEnter() {
    setIsEcomm(true);
    setIsDefault(false);
  }
  function EcommLeave() {
    setIsEcomm(false);
    setIsDefault(true);
  }
  // Ecomm Function Ends

  // Education Function Starts
  function EducationEnter() {
    setIsEducation(true);
    setIsDefault(false);
  }
  function EducationLeave() {
    setIsEducation(false);
    setIsDefault(true);
  }
  // Education Function Ends

  // Real Estate Function Starts
  function RealEstateEnter() {
    setIsRealEstate(true);
    setIsDefault(false);
  }
  function RealEstateLeave() {
    setIsRealEstate(false);
    setIsDefault(true);
  }
  // Real Estate Function Ends

  // Real Media Function Starts
  function MediaEnter() {
    setIsMedia(true);
    setIsDefault(false);
  }
  function MediaLeave() {
    setIsMedia(false);
    setIsDefault(true);
  }
  // Real Media Function Ends

  // Real Logistics Function Starts
  function LogisticsEnter() {
    setIsLogistics(true);
    setIsDefault(false);
  }
  function LogisticsLeave() {
    setIsLogistics(false);
    setIsDefault(true);
  }
  // Real Logistics Function Ends

  // Real Tourism Function Starts
  function TourismEnter() {
    setIsTourism(true);
    setIsDefault(false);
  }
  function TourismLeave() {
    setIsTourism(false);
    setIsDefault(true);
  }
  // Real Tourism Function Ends

  return (
    <>
      <div className="hoverComponent-container">
        <ul className="hoverComponent-left">
          <li onMouseEnter={healthCareEnter} onMouseLeave={healthCareLeave}>
          Basic Computer Literacy
          </li>
          <li onMouseEnter={EcommEnter} onMouseLeave={EcommLeave}>
          Coding and Programming
          </li>
          <li onMouseEnter={EducationEnter} onMouseLeave={EducationLeave}>
          Robotics and STEM
          </li>
          <li onMouseEnter={RealEstateEnter} onMouseLeave={RealEstateLeave}>
            Web Development
          </li>
          <li onMouseEnter={MediaEnter} onMouseLeave={MediaLeave}>
          Cloud Computing
          </li>
          <li onMouseEnter={LogisticsEnter} onMouseLeave={LogisticsLeave}>
          Workshops and Camps
          </li>
          <li onMouseEnter={TourismEnter} onMouseLeave={TourismLeave}>
            Artificial Intelligence (AI): 
          </li>
        </ul>

        <div className="hoverComponent-right">
          {isDefault && (
            <HoverCard
              heading={""}
              text={`EPIC’s programs are designed to cater to the learning needs of students from primary to middle school. PCI offers a range of activities and courses, including:`}
              bgClass={'default-bg'}
            />
          )}
          {isHealthCare && (
            <HoverCard
              heading={"Basic Computer Literacy"}
              text={`Introducing students to the fundamentals of computer operation, internet safety, and essential software applications.`}
        bgClass={'basic-copm-bg'}
            />
          )}
          {isEcomm && (
            <HoverCard
              heading={"Coding and Programmings"}
              text={`Engaging students in fun and interactive coding lessons using platforms like, Python, C++ and other beginner-friendly programming languages with  Data Science.`}
        bgClass={'coading-bg'}
            />
          )}
          {isEducation && (
            <HoverCard
              heading={"Robotics and STEM"}
              text={`Encouraging creativity and problem-solving through hands-on projects involving robotics, electronics, and other STEM (Science, Technology, Engineering, and Mathematics) activities.`}
      bgClass={'robotics-bg'}
            />
          )}
          {isRealEstate && (
            <HoverCard
              heading={"Web Development"}
              text={`Introduce the students about Web Technologies and engage them with Web Designing & Development technologies including HTML, CSS, Java Script, client server programming. `}
        bgClass={'web-bg'}

            />
          )}
          {isMedia && (
            <HoverCard
              heading={"Cloud Computing"}
              text={`Cloud Computing is the rapidly increasing market today in IT industry. Students will get basic introduction about cloud computing and learn technology & tools are used today for Cloud Computing.`}
        bgClass={'cloud-bg'}

            />
          )}
          {isLogistics && (
            <HoverCard
              heading={"Workshops and Camps"}
              text={`Organizing regular workshops and camps to provide intensive learning experiences and foster collaboration among young tech enthusiasts.`}
        bgClass={'workshops-bg'}
            />
          )}
          {isTourism && (
            <HoverCard
              heading={"Artificial Intelligence (AI)"}
              text={`The integration of AI into everyday applications continues to grow, enhancing efficiency and creating new opportunities across industries. It is revolutionizing how we interact with technology and solve complex problems.`}
        bgClass={'arti-int-bg'}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default HoverComponentView;
