import React, { useState } from "react";
import "./Navbar.css";
import {
    FaFacebookSquare,
    FaLinkedin,
    FaYoutubeSquare,
} from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { NavLink, Link } from "react-router-dom";
import Style from "./Navbar.module.css";
const Navbar = ({ setIsOpen }) => {
    const [showMediaIcons, setShowMediaIcons] = useState(false);
    const handleClick = () => {
        setIsOpen(true);
    }
    return (
        <>
            <nav className="main-nav">
                <div className="logo">
                    <img src="assets/logo.png" alt="" />
                </div>


                <div className="social-media">
                    <ul className="social-media-desktop">
                        <li>
                            <a href="" target="">
                                <FaFacebookSquare className="facebook" />
                            </a>
                        </li>
                        <li>
                            <a href="" target="">
                                <FaLinkedin className="linkedin" />
                            </a>
                        </li>
                        <li>
                            <a href="" target="">
                                <FaYoutubeSquare className="youtube" />
                            </a>
                        </li>
                    </ul>

                    {/* hamburget menu start  */}
                    <div className="hamburger-menu">
                        <a href="#" className="center" onClick={() => setShowMediaIcons(!showMediaIcons)}>
                            <GiHamburgerMenu className={showMediaIcons ? "c-skyblue" : ""} />
                        </a>
                    </div>
                </div>


            </nav>

            <div
                className={
                    showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
                }
            >
                <ul>
                    <li>
                        <NavLink to="/" onClick={() => setShowMediaIcons(false)}>
                            Home
                        </NavLink>
                    </li>
                    <li className="">
                        <NavLink to="/about"
                            className="nav-services"
                        >
                            About Us <span class="arrow">&#x25B6;</span>
                        </NavLink>
                        <ul className="nav-services-container">
                            <li>
                                <NavLink to={{ pathname: "/about", state: "about-us", hash: "#about-top" }} > About Us </NavLink>

                            </li>
                            <li>
                                <NavLink to={{ pathname: "/about", state: "vision", hash: "#vision" }} > Vision </NavLink>

                            </li>
                            <li>
                                <NavLink to={{ pathname: "/about", state: "mission", hash: "#mission" }} > Mission </NavLink>
                            </li>
                        </ul>
                    </li>
                    <li className="">
                        <NavLink to="/ourprogram"
                            className="nav-services">
                            Our Programs <span class="arrow">&#x25B6;</span>
                        </NavLink>
                        <ul className="nav-services-container">
                            <li>
                                <NavLink to={{ pathname: "/ourprogram", state: "vision", hash: "#Syllabus" }} >Program Syllabus </NavLink>
                            </li>
                            <li>
                                <NavLink to={{ pathname: "/ourprogram", state: "vision", hash: "#cop" }} > Cop </NavLink>
                            </li>
                            <li>
                                <NavLink to={{ pathname: "/ourprogram", state: "vision", hash: "#stop" }} > Stop </NavLink>
                            </li>
                            <li>
                                <NavLink to={{ pathname: "/ourprogram", state: "vision", hash: "#Olympiads" }} > Olympiads </NavLink>
                            </li>
                        </ul>
                    </li>
                    <li className="">
                        <NavLink

                        >
                            Online Registration
                        </NavLink>
                        <ul className="nav-services-container" >
                            <li className={Style.listItem}>
                                <NavLink
                                    to="https://docs.google.com/forms/d/e/1FAIpQLSd1DzVa4a1W17xGaWmddwxFK3hVSjeNc3Q4Le4S2PQkn9Diqw/viewform?usp=sf_link"
                                    className="nav-services"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >Epic Program</NavLink>
                            </li>
                            <li className={Style.listItem}>
                                <NavLink
                                    // to="https://docs.google.com/forms/d/e/1FAIpQLSd1DzVa4a1W17xGaWmddwxFK3hVSjeNc3Q4Le4S2PQkn9Diqw/viewform?usp=sf_link"
                                    to="https://forms.gle/jopsMrYBQ1sd9CxD8"
                                    className="nav-services"
                                    target="_blank"
                                    rel="noopener noreferrer">Online Test</NavLink>
                            </li>
                        </ul>
                    </li>
                    <li className="">
                        <NavLink
                            to="https://docs.google.com/forms/d/e/1FAIpQLSd1DzVa4a1W17xGaWmddwxFK3hVSjeNc3Q4Le4S2PQkn9Diqw/viewform?usp=sf_link"
                            className="nav-services"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Online Test
                        </NavLink>
                        <ul className="nav-services-container" >
                            <li onClick={handleClick} className={Style.listItem}>
                                <NavLink >Grade(1st to 5th)</NavLink>
                            </li>
                            <li onClick={handleClick} className={Style.listItem}>
                                <NavLink >Grade(6th to 8th)</NavLink>
                            </li>
                            <li onClick={handleClick} className={Style.listItem}>
                                <NavLink >Grade(9th to 10th)</NavLink>
                            </li>
                            <li onClick={handleClick} className={Style.listItem}>
                                <NavLink >Grade(11th to 12th)</NavLink>
                            </li>
                        </ul>
                    </li>


                    <li>
                        <NavLink to="/contact" onClick={() => setShowMediaIcons(false)}>
                            Contact Us
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/faq" onClick={() => setShowMediaIcons(false)}>
                            FAQ's
                        </NavLink>
                    </li>
                </ul>
                {/* <div className="hamburger-menu">
                        <a href="#" className="center" onClick={() => setShowMediaIcons(!showMediaIcons)}>
                            <GiHamburgerMenu className={showMediaIcons ? "c-skyblue" : ""} />
                        </a>
                    </div> */}
            </div>
        </>
    );
};

export default Navbar;









