import React from "react";

const SideNav3 = () => {
  return (
    <>

      <nav className="sidenav">
        <ul>
          <li>
            <a href="#about-top">
              Top
            </a>
          </li>
          <li>
            <a href="#who">Who We Are</a>
          </li>
          <li>
            <a href="#Education">Bridge In<br />Education</a>
          </li>
          <li>
            <a href="#vision">Vision</a>
          </li>
          <li>
            <a href="#mission">Mission</a>
          </li>
          
        </ul>
      </nav>
    </>
  );
};

export default SideNav3;
