import React from 'react'
import Style from "./WaitBox.module.css";
import { RxCross2 } from "react-icons/rx";

const WaitBox = ({ setIsOpen }) => {
  const handleClose = () => {
    setIsOpen(false);
  }
  return (
    <>
      <div className={Style.relativeBox}>
        <div className={Style.overlay}></div>
      </div>
      <div className={Style.box}>
        <div onClick={handleClose}>
          <RxCross2 className={Style.cross} />
        </div>
        <div className={Style.content}>
          <p className={Style.paragraph}>The tests will start from: </p>
          <span className={Style.date}>07-Sept-2024</span>
        </div>
      </div>
    </>
  )
}

export default WaitBox