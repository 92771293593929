// import React, { useState } from "react";
import "./Home.css";
import "./Course.css"
import "../../App.css";
import "../../components/cards/Cards2.css"
import "../../components/cards/Cards.css"
import SideNav from "../../components/Navbar/SideNav";
import Objective from "./Objective/Objective";
import CourseCard from "./CourseCard";
// import CourseSlider from "./CourseSlider";
import { NavLink } from "react-router-dom";
import WebDevelopmentSlider2 from "../../components/cardsliders/WebDevelopmentSlider2";
import Carousal from "../../components/carousal/Carousal";
import WaitBox from "../../components/temp/WaitBox";

const Home = () => {
  return (
    <>

      <SideNav />
      <main>
        <section className="webdevelop-top2">
          <div id="home-top"></div>
          <Carousal />
          <div className="webdevelopment-top container">
            <div className="webdevelopment-top-left hero">
              {/* <h1 className="span1">Innovate - Educate - Elevate <br /> <span>Transforming Education - Empowering Youth.</span> </h1> */}
              {/* <h1 className="span2">2. Your Journey to Technical Excellence Starts Here.</h1>
              <h1 className="span3">3. Inspiring Technical Excellence Across India.</h1> */}
              {/* <NavLink className="cta2" to={"/contact"}>
                Get a Quote
              </NavLink> */}
            </div>
            {/* <div className="webdevelopment-top-right">
              <WebDevelopmentSlider2 />
            </div> */}
          </div>
        </section>


        {/* Start Front Intro Section  */}
        <section className="article front-intro ">
          <div className="content">
            <h1 className="">EPIC (Technical Education Outreach Program Council of India)
            </h1>
            <p>
              At the EPIC (Technical Education Outreach Program Council of India),
              we believe that every child deserves the opportunity to explore and
              excel in the world of technology. Our mission is to bridge the digital
              divide by providing high-quality computer education to junior, primary,
              middle school students across India. By fostering digital literacy, critical
              thinking, and problem-solving skills, we aim to prepare the next generation for a technology-driven future.
            </p>
            <p>
              We envision a future where every child, regardless of their background, has access to the skills and knowledge needed to thrive in an increasingly digital world. Through our comprehensive and inclusive programs.
            </p>
            <NavLink to={"/about"} className="cta">
              Read More...
            </NavLink>
          </div>

          <figure className="image-wrap">
            <img src="assets/main.jpg" alt="Shiv Shakti Gurukulam" />
          </figure>
        </section>
        {/* End Front Intro Section  */}

        {/* Start Home Services Section  */}
        <br /><br />
        <h1 className="section-title">
          EPIC <span>Programs</span>
        </h1>
        <div className='relativeContainer'>
          <div id="programs"></div>
        </div>
        <div className="Home-container-home">
          <section className="main section-wrap">
            <div className="home-services ">
              <div className="home-services-header">
              </div>
              <br />
              <div className="all-home-services">
                <div className="card2-item article">
                  <div className="card2-info">
                    {/* <h1> </h1> */}

                    <p>
                      EPIC programs are specifically designed and aiming to foster a generation of tech-savvy individuals equipped with the knowledge and tools needed to thrive in today's digital age. We believe that learning should be with practical approach & accessible to all. Our teaching methods focus on interactive and practical learning, ensuring that students not only understand the concepts but also apply them in real-world scenarios.
                    </p>
                    {/* <NavLink to={"/technicalTraining"} className="cta">
                      Read More...
                    </NavLink> */}
                  </div>
                  <div className="card2-img">
                    <img src="assets/program.png" alt="img" />{" "}
                  </div>
                </div>
              </div>
            </div>
          </section >
          <CourseCard />
        </div>
        {/*  End Home Services Section */}

        {/*  Start Insights Section */}

        {/* <section className="insights-section container">
          <div className="course-top-right">
            <CourseSlider
              src1={"assets/on;ine.jpg"}
              title1={"Access from Home;"}
              text1={
                " Live & Online Interactive classes."
              }
              src2={"assets/moths.jpg"}
              title2={"Long Duration"}
              text2={
                "06 Month Duration of each program."
              }
              src3={"assets/fees.jpg"}
              title3={"No Exam fee"}
              text3={
                "To Appear in Olympiads Exams*"
              }
              src4={"assets/nofees.jpg"}
              title4={"No Tuition fee"}
              text4={"All Learning Programs are Free*"
              }
            />
          </div>
          <div style={{ marginTop: '30px' }}></div>
          <div className="course-top-right">
            <CourseSlider
              src1={"assets/get.jpg"}
              title1={"Get Awarded"}
              text1={
                "Medals & Certificate of Participation in Olympiads.."
              }
              src2={"assets/certificate.jpg"}
              title2={"Get Certified"}
              text2={
                "Certificate of Program completion by EPIC & ICOESS."
              }
              src3={"assets/flex.jpg"}
              title3={"Learn with Fun;"}
              text3={
                "Flexible Week End Classes."
              }
              src4={"assets/syllabus.jpg"}
              title4={"No Cost syllabus"}
              text4={"Free course material after each module."
              }
            />
          </div>
        </section> */}
        <br /><br /><br />
        <h1 className="section-title">
          Latest<span>Programs</span>
        </h1>
        <br /><br />
        <div className="Home-container-home">
          {/* <div className="home-services-header">
            </div> */} <br /><br />
          <div className="card2-item article">
            <div className="card2-info">
              <h1>
                COP (Computer Outreach Program)
              </h1>
              <p>
                EPICs Computer Outreach Program, where we bring the world of technology directly to junior, primary and middle school students across India. Our goal is simple: to equip every child with essential digital skills and inspire a passion for learning in a digital world.

              </p>
              <NavLink to={{ pathname: "/ourprogram", state: "vision", hash: "#cop" }} className={'cta'}> Read More... </NavLink>
            </div>
            <div className="card2-img">
              <img src="assets/cop.jpg" alt="img" />{" "}
            </div>
          </div>
          <div className="card2-item ">
            <div className="card2-info">
              <h1>STOP (Science & Technology Outreach Program)
              </h1>
              <p>
                We believe in bridging the gap between classroom learning and real-world applications. Through hands-on activities, engaging workshops, and interactive sessions, we ignite curiosity and inspire the next generation of innovators. Our mission is to make science and technology accessible and exciting for students of all.

              </p>
              <NavLink to={{ pathname: "/ourprogram", state: "vision", hash: "#stop" }} className={'cta'}> Read More... </NavLink>
            </div>
            <div className="card2-img">
              <img src="assets/science.png" alt="img" />{" "}
            </div>
          </div><div className="card2-item ">
            <div className="card2-info">
              <h1>National Level Olympiad Exams
              </h1>
              <p>
                National Level Olympiad Exams play a crucial role in nurturing talent, fostering academic excellence, and preparing students to face future challenges in their chosen fields. They serve as a valuable avenue for personal and academic growth.
                EPIC conducts National Level Olympiad Exams to identify and nurture young talent in specific fields such as Science, Computer Science, Information Technology and more. These exams aim to Promote Academic Excellence, Benchmarking Skills, Foster Critical Thinking, Recognition and Awards.

              </p>
              <NavLink to={{ pathname: "/ourprogram", state: "vision", hash: "#Olympiads" }} className={'cta'}> Read More... </NavLink>
            </div>
            <div className="card2-img">
              <img src="assets/Olym.jpg" alt="img" />{" "}
            </div>
          </div>
          <br /><br />
        </div>
        {/*  End Insights Section
        {/*  Start Service Areas Section */}
        <br /><br />
        <br /><br />
        <div className='relativeContainer'>
          <div id="program-objective"></div>
        </div>
        <section className="service-areas section container">
          <Objective />
        </section>
        <br /><br />

        {/*  End Service Areas Section */}

        {/*  Start inside icoess Section */}

        {/* <section id="goals" className=" inside-icoess container ">
   
  </section> */}
        {/*  End inside icoess Section */}

        {/* Start About Us Section  */}

        {/* End About Us Section  */}
        {/*  Start Contact Section */}
        <div className="" id="home-contact-section">
          {/* <ContactSection
            text={
              "Talk to us for your business needs, all staffing requirements and anything on technology. We would love to here from you & share our expertise with you"
            }
          /> */}
        </div>
        <div className='relativeContainer'>
          <div id="goals"></div>
        </div>
        {/*  End Contact Section */}
        <h1 className="section-title">
          Towards Achieving<span> Our Goal</span>
        </h1>
        <br /><br /><br />
        <div className="Home-container-home">
          {/* <div className="home-services-header">
            </div> */} <br /><br />
          <div className="article card2-item ">
            <div className="card2-info">
              <h1>
                Enhance Curriculum
              </h1>
              <p>
                EPIC is dedicated to transforming the educational landscape by integrating cutting-edge technology and innovative teaching methodologies into the curriculum. Our aim is to provide students with a dynamic and engaging learning.
              </p>
            </div>
            <div className="card2-img">
              <img src="assets/hello.jpg" alt="Shiv Shakti Gurukulam" />
            </div>
          </div>
          <div className="article card2-item ">
            <div className="card2-info">
              <h1>Industry Partnerships
              </h1>
              <p>
                EPIC is committed to bridging the gap between education and industry by forging strong partnerships with leading technology companies and organizations. These collaborations provide students with invaluable opportunities for hands-on experience, internships, and mentorship from industry experts.
              </p>
            </div>
            <div className="card2-img">
              <img src="assets/indutri.png" alt="Shiv Shakti Gurukulam" />
            </div>
          </div><div className=" article card2-item ">
            <div className="card2-info">
              <h1>
                Scholarships and Grants
              </h1>
              <p>
                EPIC is dedicated to making quality technical education accessible to all students by offering scholarships and grants to deserving individuals. Our financial aid programs are designed to support talented students from diverse backgrounds, ensuring that financial constraints do not hinder their educational and career aspirations.
              </p>
            </div>
            <div className="card2-img">
              <img src="assets/scholar.jpg" alt="Shiv Shakti Gurukulam" />
            </div>
          </div>
          <br /><br />
        </div>
        <br /><br /><br />
      </main >
    </>
  );
};

export default Home;
