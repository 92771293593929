import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import "./About.css"
import HoverComponentView from "../../components/hoverComponentView/HoverComponentView"
import SideNav3 from '../../components/Navbar/SideNav3'
const About = () => {

  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);
  return (
    <>
      <SideNav3 />
      <div id="about-top"></div>
      <div className="content ">
        <br /><br /><br />
        <h1 className="headline1 section-title">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;About Us</span>
        </h1>
      </div>
      <section className="section center about">
        <div className="it">
          <div className="card-about article">
            <div className="card2-info about-hover">
              <h1>Technical Education Outreach Program Council of India (EPIC)
              </h1>
              {/* <h4 className="heading4">FULL-TIME TECH TALENT</h4> */}
              <p>
                Technical Education Outreach Program Council of India is a dedicated initiative focused on bridging the digital divide and empowering young minds with essential computer skills. EPIC is committed to educate each student of K-12 (primary, Middle & Upper Middle) level of all discipline by providing Technical, Computer & Information Technology Education to each student across nation.
              </p>
            </div>
            <div className="card2-img">
              <img src="assets/tech.webp" alt="tech" />{" "}
            </div>
          </div>
        </div>
      </section>

      {/* End Front Intro Section  */}
      <div className='relativeContainer'>
        <div id="who"></div>
      </div>
      <div className="content">
        <h1 className="headline section-title">Who We <span>Are</span> </h1>
      </div>
      <section className=" section center about">
        <div className="it">
          <div className="card-about article">
            <div className="card2-info">
              <h1>
              </h1>
              {/* <h4 className="heading4">FULL-TIME TECH TALENT</h4> */}
              <p>
                We are a passionate team of educators, technologists, and volunteers committed to bridging the digital divide in India. Our diverse team brings together expertise in computer science, Information Technology, education outreach, all working towards a common goal: to make computer education accessible and engaging for all children, regardless of their background or location.
              </p><br />
              <p>As India is on the move & lot of different sectors have started growing at good pace, it is IT sector which has really been the driver of growth for last decade or more, and has the potential to lead India for another couple of decades at the very least. But there is one small hitch though, we don’t have enough trained manpower and the reason behind is, poor education system.
              </p><br />
              <p>Our current Computer/IT education does not meet the evolving demands of today's advanced technologies. As a result, our young students, upon progressing to the middle school level, often find themselves lagging behind when they graduate to Higher education. </p>
            </div>
            <div className="card2-img">
              <img src="assets/team.webp" alt="img" />{" "}
            </div>
          </div>
        </div>
      </section>
      <br /><br /><br /><br />
      {/* Start Home Services Section  */}
      <div className='relativeContainer'>
        <div id="Education"></div>
      </div>
      <div className="content">
        <h1 className="headline section-title-left">To Bridge The GAP in  <span>Education</span> </h1>
        <h3 className='about-left-h3 '>To bridge the gap of Latest Technical computer education from Traditional Teachings, we are implementing the following strategies-</h3>
      </div>
      <br /><br />
      <div className="Home-container-home">
        <section className=" section center about2">
          <div className="it">
            <div className="card-about hover-about article">
              <div className="card2-info">
                <h1>Introduce Advanced Topics
                </h1>
                {/* <h4 className="heading4">FULL-TIME TECH TALENT</h4> */}
                <p>
                  Introduce fundamental concepts of coding, programming, and emerging technologies at the middle school level. By introducing advanced topics early, students can develop a robust understanding of computer science principles, which will help them tackle more complex subjects with confidence as they progress in their education. This approach also nurtures critical thinking, creativity, and problem-solving skills that are essential in today's technology-driven world.
                </p>
              </div>
              <div className="card2-img">
                <img src="assets/fundamental.jpg" alt="fundamental" />{" "}
              </div>
            </div>
          </div>
        </section>
        <section className=" section center">
          <div className="it-staffing-services">
            <div className="card-about hover-about2 article">
              <div className="card2-info">
                <h1>Hands-on Projects</h1>
                {/* <h4 className="heading4">FULL-TIME TECH TALENT</h4> */}
                <p>
                  Incorporate more practical, hands-on projects and labs that encourage experimentation and problem-solving.
                </p>
              </div>
              <div className="card2-img">
                <img src="assets/handson.jpg" alt="handson" />{" "}
              </div>
            </div>
          </div>
        </section>
        <section className=" section center">
          <div className="it-staffing-services">
            <div className="card-about hover-about2 article">
              <div className="card2-info">
                <h1>Coding Clubs and Competitions</h1>
                <h4 className="heading4">Establish coding clubs and encourage participation in competitions like the National Junior Coders Olympiad to foster a competitive and collaborative learning environment.
                </h4>
                <p>
                  <h3>&bull; Online Learning Platforms</h3>
                  <p> &nbsp;&nbsp;&nbsp;&nbsp;Utilize online learning platforms and resources to supplement traditional teaching <br /> &nbsp;&nbsp;&nbsp;&nbsp;methods.
                  </p>
                </p>
              </div>
              <div className="card2-img">
                <img src="assets/pexels-cottonbro-4709291.jpg" alt="pexels" />{" "}
              </div>
            </div>
          </div>
        </section>

        <section className=" section center">
          <div className="it-staffing-services">
            <div className="card-about hover-about2 article">
              <div className="card2-info">
                <h1>Mentorship Programs:</h1>
                {/* <h4 className="heading4"></h4> */}
                <p>
                  <h3>&bull; Industry Mentorship: </h3>
                  <p> &nbsp;&nbsp;&nbsp;&nbsp;We Create mentorship programs that connect students with professionals in the tech industry.
                  </p>
                </p>
                <p>
                  <h3>&bull; Peer Tutoring: </h3>
                  <p> &nbsp;&nbsp;We encourage & establish peer tutoring systems where advanced students can help their peers &nbsp;&nbsp;&nbsp;&nbsp;understand complex topics.
                  </p>
                </p>
              </div>
              <div className="card2-img">
                <img src="assets/mentorship.jpg" alt="mentorship" />{" "}
              </div>
            </div>
          </div>
        </section>
        <section className=" section center">
          <div className="it-staffing-services">
            <div className="card-about hover-about2 article">
              <div className="card2-info">
                <h1>Integrative Learning:</h1>
                {/* <h4 className="heading4">
                </h4> */}
                <p>
                  <h3>&bull; Interdisciplinary Projects: </h3>
                  <p>&nbsp;&nbsp;Encourage interdisciplinary projects that integrate computer science with other subjects like &nbsp;&nbsp;&nbsp;&nbsp;math, science, and art.
                  </p>
                </p>
                <p>
                  <h3>&bull; Real-World Applications :</h3>
                  <p>&nbsp;&nbsp;Showcase real-world applications of technology to demonstrate its relevance and
                    importance in &nbsp;&nbsp;&nbsp;&nbsp;various fields.
                  </p>
                </p>
              </div>
              <div className="card2-img">
                <img src="assets/integrated.jpg" alt="integrated" />{" "}
              </div>
            </div>
          </div>
        </section>
        <section className=" section center">
          <div className="it-staffing-services">
            <div className="card-about hover-about2 article">
              <div className="card2-info">
                <h1>Assessment and Feedback:</h1>
                {/* <h4 className="heading4">
                </h4> */}
                <p>
                  <h3>&bull; Regular Assessments: </h3>
                  <p>&nbsp;&nbsp;Implement regular assessments to identify gaps in knowledge and adjust teaching methods &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;accordingly.
                  </p>
                </p>
                <p>
                  <h3>&bull; Feedback Mechanisms: </h3>
                  <p>&nbsp;&nbsp;Create feedback mechanisms where students can share their learning experiences and suggest &nbsp;&nbsp;&nbsp;&nbsp;improvements.
                  </p>
                </p>
              </div>
              <div className="card2-img">
                <img src="assets/assessments.jpg" alt="assessments" />{" "}
              </div>
            </div>
          </div>
        </section>
        <section className=" section center">
          <div className="it-staffing-services">
            <div className="card-about hover-about2 article">
              <div className="card2-info">
                <h1>Integration with Other Subjects:</h1>
                {/* <h4 className="heading4">
                </h4> */}
                <p>
                  <h3>&bull; STEM Integration: </h3>
                  <p> &nbsp;&nbsp;Integrate computer science with other STEM subjects (Science, Technology, Engineering,and  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mathematics) to provide a multidisciplinary learning experience.
                  </p>
                </p>
                <p>
                  <h3>&bull; Cross-Curricular Projects: </h3>
                  <p>&nbsp;&nbsp;&nbsp;Develop projects that combine computer science with subjects like art, music, or social  studies to &nbsp;&nbsp;&nbsp;&nbsp;highlight the versatility of coding skills.
                  </p>
                </p>
              </div>
              <div className="card2-img">
                <img src="assets/stem.jpg" alt="stem" />{" "}
              </div>
            </div>
          </div>
        </section>
        <h3 className='left-h3'>By implementing these strategies, we assures the students can effectively bridge the gap in <br /> computer education between middle and higher levels, ensuring that students are well-prepared <br /> for the technological demands of the future.</h3>
        <br /><br /><br />
      </div>
      <br /><br /><br />
      <br /><br /><br />
      {/* =================================== */}
      {/* Start Home Services Section  */}
      <div className="content">
        <h1 className="headline section-title">How EPIC Programs are different from <span>Traditional Education</span> </h1>
      </div>
      <br /><br />
      <div className="Home-container-home">
        <section className="article ">
        </section>
        <section className=" section center about2">
          <div className="it">
            <div className="card-about hover-about3 article">
              <div className="card2-img">
                <img src="assets/epic-diff..jpg" alt="epic" />{" "}
              </div>
              <div className="card2-info">
                <h1></h1>
                {/* <h4 className="heading4">FULL-TIME TECH TALENT</h4> */}
                <p>
                  By introducing students to the basics of computing early on, we are laying the foundation for their future academic and professional success. Our program helps students develop critical thinking skills, problem-solving abilities, and the confidence to navigate the digital landscape.
                </p>
                <br />
                <p>
                  Our program is specifically designed and aiming to foster a generation of tech-savvy individuals equipped with the knowledge and tools needed to thrive in today's digital age.
                </p>
                <br />
                <p>
                  We believe that every child deserves the opportunity to learn and explore the world of Science & Technology, mainly Information Technology, which is why we strive to reach out to schools and communities across India.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="insights" className="main section-wrap">
          <div className="home-services ">
            <div className="home-services-header">
              <h1 className="section-title">
                EPIC <span>Programs</span>
              </h1>
            </div>
            {/* <div className="all-home-services">
              <div className="card-about article">
                <div className="card2-info">
                  <p>
                  EPIC’s programs are designed to cater to the learning needs of students from primary to middle school. PCI offers a range of activities and courses, including
                  </p>
                </div>
                <div className="card2-img">
                  <img src="assets/program.png" alt="img" />{" "}
                </div>
              </div>
            </div> */}
          </div>
        </section >

        {/*  End Home Services Section */}

        {/*  Start Insights Section */}
        {/*  End Insights Section
        {/*  Start Service Areas Section */}

        {/*  End Service Areas Section */}

        {/*  Start Contact Section */}
        <br />
        <section className="industries-weserve-container section">
          <HoverComponentView />
        </section>
        <br /><br />
      </div>
      {/* ================================================ */}
      {/* ================================================ */}
      {/* ================================================ */}
      {/* ================================================ */}
      {/* ================================================ */}

      <br /><br /><br />
      <br /><br /><br />
      <div className='relativeContainer'>
        <div id="vision">Vision</div>
      </div>
      <div className="content" >
        <h1 className="headline2 section-title">
          <span>Vision</span>
        </h1>
      </div>
      <br /><br />
      <div className="Home-container-home">
        <section className="article ">
        </section>
        <section className=" section center about2">
          <div className="it">
            <br /><br />

            <div className="card-about hover-about44 article">
              <div className="card2-img">
                <img src="assets/child.jpg" alt="child" />{" "}
              </div>
              <div className="card2-info">
                <h1>Empowering Every Child with Future-Ready Computer Education in India</h1>
                {/* <h4 className="heading4">FULL-TIME TECH TALENT</h4> */}
                <p>
                  Our vision is to provide high-quality, inclusive, and forward-thinking computer education to primary and middle school students across India. We aim to bridge the digital divide by ensuring that every child, regardless of their background, has access to the knowledge and skills needed to thrive in the digital age. Through innovative teaching methods, community engagement, and strategic partnerships, we will create a robust foundation for the next generation of tech-savvy leaders and innovators.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className=" section center">
          <div className="it-staffing-services">
            <div className="card-about hover-about4 article">
              <div className="card2-info">
                <h1>Universal Access to Quality Computer Education:</h1>
                {/* <h4 className="heading4">FULL-TIME TECH TALENT</h4> */}
                <p>
                  &bull; Ensure that every primary and middle school student has access to high-quality computer &nbsp;&nbsp;&nbsp;&nbsp;education, irrespective of their geographical location or socio-economic status.
                </p>
                <p>&bull; Develop and distribute standardized curriculum and resources tailored to different learning &nbsp;&nbsp;&nbsp;&nbsp;environments, including urban, rural, and remote areas.
                </p>
              </div>
              <div className="card2-img">
                <img src="assets/universal.jpg" alt="universal" />{" "}
              </div>
            </div>
          </div>
        </section>
        <section className=" section center">
          <div className="it-staffing-services">
            <br /><br />
            <div className="card-about hover-about4 article">
              <div className="card2-info">
                <h1>Promoting Lifelong Learning and Curiosity:</h1>
                {/* <h4 className="heading4">FULL-TIME TECH TALENT</h4> */}
                <p>
                  &bull; &nbsp;Instill a culture of lifelong learning and curiosity about technology and its applications.
                </p>
                <p>&bull; Provide opportunities for students to explore emerging technologies and stay updated with
                  &nbsp;&nbsp;&nbsp;&nbsp;industry trends.
                </p>
              </div>
              <div className="card2-img">
                <img src="assets/learningneverend.jpg" alt="learningneverend" />{" "}
              </div>
            </div>
          </div>
        </section>
        <section className="section center">
          <div className="it-staffing-services">
            <div className="card-about hover-about4  article">
              <div className="card2-info">
                <h1>Scaling and Sustainability:</h1>
                {/* <h4 className="heading4">FULL-TIME TECH TALENT</h4> */}
                <p>
                  &bull; Develop scalable models that can be replicated and adapted to different regions and contexts &nbsp;&nbsp;&nbsp;&nbsp;across India.
                </p>
                <p>&bull; Ensure the long-term sustainability of the program through government support, private  sector &nbsp;&nbsp;&nbsp;funding, and community involvement.
                </p>
              </div>
              <div className="card2-img">
                <img src="assets/scalable.png" alt="scalable" />{" "}
              </div>
            </div>
          </div>
        </section>
        <br /><br />
      </div>
      {/* ================================================ */}
      {/* ================================================ */}
      {/* ================================================ */}
      {/* ================================================ */}
      {/* ================================================ */}
      <br /><br /><br />
      <br />
      <div className='relativeContainer'>
        <div id='mission'></div>
      </div>
      <div className="content" >
        <h1 className="headline2 section-title">
          <span>Mission</span>
        </h1>
      </div>
      <br /><br />
      <div className="Home-container-home">
        <section className="article ">
        </section>
        <section className=" section center about2">
          <div className="it">
            <br /><br />
            <div className="card-about hover-about5 article">
              <div className="card2-img">
                <img src="assets/missio-main.png" alt="mission" />{" "}
              </div>
              <div className="card2-info">
                {/* <h1>Empowering Every Child with Future-Ready Computer Education in India</h1> */}
                {/* <h4 className="heading4">FULL-TIME TECH TALENT</h4> */}
                <p>
                  To provide comprehensive, inclusive, and forward-thinking computer education to junior, primary, and middle school students across India, equipping them with essential digital literacy, coding skills, and a strong foundation in computer science to thrive in a technology-driven world.
                </p>
              </div>
            </div>
          </div>
        </section>
        <h1 style={{ textAlign: 'start', fontSize: '24px', marginLeft: '110px' }} className="headline section-title">Core Mission <span> Objectives:</span></h1>
        <section className=" section center">
          <div className="it-staffing-services">
            <div className="card-about hover-about55 article">
              <div className="card2-info">
                <h1>Develop and Implement a Progressive Curriculum:</h1>
                {/* <h4 className="heading4">FULL-TIME TECH TALENT</h4> */}
                <p><h3>&bull; Age-Appropriate Content:</h3>
                  &nbsp;&nbsp;Create a curriculum that introduces computer science concepts progressively from junior to &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;middle school, ensuring age-appropriate content and learning experiences.
                </p>
                <p>
                  <h3>&bull; Fundamentals and Advanced Topics: </h3>
                  &nbsp;&nbsp;&nbsp;Cover fundamental computer skills, digital literacy, and basic programming for juniors, with more &nbsp;&nbsp;&nbsp;advanced topics like algorithms, data structures, and cyber security for primary and middle school &nbsp;&nbsp;&nbsp;&nbsp;students.
                </p>
              </div>
              <div className="card2-img">
                <img src="assets/develop.webp" alt="develop" />{" "}
              </div>
            </div>
          </div>
        </section>
        <section className=" section center">
          <div className="it-staffing-services">
            <div className="card-about hover-about55 article">
              <div className="card2-info">
                <h1>Promote Hands-on and Experiential Learning: </h1>
                {/* <h4 className="heading4">FULL-TIME TECH TALENT</h4> */}
                <p><h3>&bull; Interactive Learning:</h3>
                  &nbsp;&nbsp;Emphasize hands-on projects, coding exercises, and real-world applications to make learning &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;interactive and practical.
                </p>
                <p>
                  <h3>&bull; Competitions and Clubs: </h3>
                  &nbsp;&nbsp;&nbsp;Organize coding competitions, hackathons, and computer clubs to stimulate creativity, teamwork,  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;and innovation.
                </p>
              </div>
              <div className="card2-img">
                <img src="assets/DSC02935-1.jpg" alt="DSC02935-1" />{" "}
              </div>
            </div>
          </div>
        </section>
        <section className=" section center">
          <div className="it-staffing-services">
            <div className="card-about hover-about55 article">
              <div className="card2-info">
                <h1>Conduct Regular Assessments and Feedback Mechanisms:</h1>
                {/* <h4 className="heading4">FULL-TIME TECH TALENT</h4> */}
                <p><h3>&bull; Performance Evaluation:</h3>
                  &nbsp;&nbsp;&nbsp;&nbsp;Implement assessment tools to measure student progress and program effectiveness.
                </p>
                <p>
                  <h3>&bull; Continuous Improvement:</h3>
                  &nbsp;&nbsp;&nbsp;Collect feedback from students, teachers, and stakeholders to continuously refine and improve the &nbsp;&nbsp;&nbsp;&nbsp;curriculum and teaching methods.
                </p>
              </div>
              <div className="card2-img">
                <img src="assets/missiom-asses.jpg" alt="missiom-asses" />{" "}
              </div>
            </div>
          </div>
        </section>
        <br /><br />
      </div>
      <br /><br />
      <br /><br />
      {/*  End Home Services Section */}

      {/*  Start Insights Section */}
      {/*  End Insights Section
        {/*  Start Service Areas Section */}

      {/*  End Service Areas Section */}

      {/*  Start Contact Section */}

    </>
  )
}

export default About
