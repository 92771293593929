import React from 'react'
import "./CourseCard.css"
const CourseCard2 = () => {
  return (
    <>
      <div className="Card-main-container">
        <div className="card3">
          <div className="card3-image-container">
            <img src="assets/from-home.jpg" alt="home" />
          </div>
          <div className="card3-content">
            <h3>Access from Home</h3>
            <p>
              Live & Online Interactive classes.
            </p>
          </div>
        </div>
        <div className="card3">
          <div className="card3-image-container">
            <img src="assets/6-month-course-.jpg" alt="6" />
          </div>
          <div className="card3-content">
            <h3>Long Duration</h3>
            <p>
            06 Month Duration of each program.
            </p>
          </div>
        </div>
        <div className="card3">
          <div className="card3-image-container">
          <img src="assets/pexels-karolina-grabowska-4386431.jpg" alt="money" />

          </div>
          <div className="card3-content">
            <h3>No Exam fee</h3>
            <p>
            To Appear in Olympiads Exams.
            </p>
          </div>
        </div>
        <div className="card3">
        <div className="card3-image-container">
        <img src="assets/tution-no.jpg" alt="tution" />
        </div>
        <div className="card3-content">
          <h3>Affordable fees</h3>
          <p>
          All Learning Programs are Free.
          </p>
        </div>
      </div>
      </div>
      <div className="Card-main-container">
      <div className="card3">
        <div className="card3-image-container">
          <img src="assets/pexels-cottonbro-6651190.jpg" alt="pexels" />
        </div>
        <div className="card3-content">
          <h3>Get Certified</h3>
          <p>
          Certificate of Program completion by EPIC & ICOESS.
          </p>
        </div>
      </div>
      <div className="card3">
        <div className="card3-image-container">
          <img src="assets/pexels-shvetsa-6250930.jpg" alt="pexels" />
        </div>
        <div className="card3-content">
          <h3>Get Awarded</h3>
          <p>
          Medals & Certificate of Participation in Olympiads..
          </p>
        </div>
      </div>
      <div className="card3">
        <div className="card3-image-container">
          <img src="assets/learn-with-fun.webp" alt="fun" />
        </div>
        <div className="card3-content">
          <h3>Learn with Fun</h3>
          <p>
          Flexible Week End Classes
          </p>
        </div>
      </div>
      <div className="card3">
        <div className="card3-image-container">
          <img src="assets/syllabus.jpg" alt="img" />
        </div>
        <div className="card3-content">
          <h3>No Cost syllabus</h3>
          <p>
          Free course material after each module.
          </p>
        </div>
      </div>
      </div>
      <h4 className="our-program left">
<span>*</span>Only registration charges applicable. <span>*</span>
      </h4>
      <h4 className="our-program">
<span>*</span>Only Grade wise qualified students will be awarded <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;and get medals; rest will get certificate of participation.<span>*</span>
      </h4>
    </>
  )
}

export default CourseCard2;