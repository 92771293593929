import React, { useState } from 'react';
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import "./Contact.css";
import axios from 'axios';
const Contact = () => {
    const initialFormData = { name: "", email: "", phone: "", message: "" };
    const [response, setResponse] = useState("");
    const [formData, setFormData] = useState(initialFormData);
    const [mailStatus, setMailStatus] = useState("Send");

    async function submit(e) {
        e.preventDefault();
        if (!formData.name) return;
        if (!formData.phone) return;
        if (!formData.email) return;
        if (!formData.message) return;
        console.log("Sending the email...")
        const currData = { ...formData };
        console.log("Current Data:", currData);
        setMailStatus("Sending...");
        try {
            // await axios.post("https://icoesolutionserver.onrender.com/api/contact-email", currData)
            // await axios.post("https://icoessolution-server.onrender.com/api/contact-email", currData)
            await axios.post("https://epic-backend-snvs.onrender.com/api/contact-email", currData)
                // await axios.post("http://localhost:5000/api/contact-email", currData)
                .then(resp => {
                    setResponse(resp.data)
                    setFormData(initialFormData);
                    console.log("")
                    setMailStatus("Send");
                })
                .catch((e) => {
                    alert('Something went wrong')
                    console.log(e);
                    setMailStatus("Send");
                })
        }
        catch (e) {
            console.log(e)
        }
    }

    const handleChange = (e) => {
        const { value, id } = e.target;
        setFormData({ ...formData, [id]: value });
        // console.log(formData);
    }

    return (
        <>
            <div className="main-containnar">
                <div className="contact-us">
                    <br /><br />
                    <h1 className='contact-us1'>Contact Us</h1>
                </div>
                <br />
                <div className="second-conttainar">
                    <div>
                        <div class="info"><FaLocationDot /> &nbsp;&nbsp;&nbsp;&nbsp;Address</div>
                        <h2 className='main-add'>
                            H.N.249, Peptech Town<br />
                            Chhatarpur(M.P.),India<br />
                            471001
                        </h2>
                        <br /><br /><br />
                        <div class="info"><FaPhoneAlt />&nbsp;&nbsp;&nbsp;&nbsp;Phone Number</div>
                        <h2 className='main-add'>6269168433, 9981810146</h2>
                        <br /><br /><br />
                        <div class="info"><IoMdMail />&nbsp;&nbsp;&nbsp;&nbsp;Email</div>
                        <h2 className='main-add'>support@epicorg.in</h2>
                    </div>
                    <div className="form-conttain">
                        <form action='POST' onSubmit={submit}>
                            <h1 className='contact-h1'>Send Message</h1>
                            <br /><br />
                            <input type="text" className="form-text" placeholder="Full name" required value={formData.name} id="name" onChange={handleChange} /><br />
                            <input type="number" className="form-text" placeholder="Phone" required value={formData.phone} id="phone" onChange={handleChange} /><br />
                            <input type="email" className="form-text" placeholder="Email" required value={formData.email} id="email" onChange={handleChange} /><br />
                            <input type="text" className="form-text" placeholder="Message" required value={formData.message} id="message" onChange={handleChange} /><br />
                            <button type="submit" className="button">{mailStatus}</button>
                        </form>
                    </div>
                </div>
                <br /><br />
            </div>
        </>
    );
}

export default Contact;